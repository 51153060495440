
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'course wise trainning statistics',
  components: {
    Form,
    Datatable,
    SearchBar,
    Swal,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      tranche: [] as any,
      association: [] as any,
       TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      optionsTPartner: [] as any,
      btnCheck: false,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '200px',
        },
        {
          name: 'Unique Course',
          key: 'activity_report',
          sortable: true,
        },
        {
          name: 'Code',
          key: 'code',
          sortable: true,
        },
        {
          name: 'Name of industry Association / Govt. Org',
          key: 'association',
          sortable: true,
          width: '300px',
        },
        {
          name: 'Course Found',
          key: 'course',
          sortable: true,
        },
      ],
      tranche_id: '' as any,
      report_type:'' as any,
      entity_id: [] as any,
      course_type: '' as any,
      showTrainningStatistics: false,
      componentKey: 0,
      actionActive: false,
      activityReport: [] as any,
      selectAll: {
        id: 'all',
        label: 'All',
      } as any,
      TotalTrainee: 0 as any,
      Totalenrollment: 0 as any,
      TotalenrollmentFemale: 0 as any,
      TotalenrollmentFemalePercentage: 0 as any,

      Totalassesment: 0 as any,
      TotalassesmentFemale: 0 as any,
      TotalassesmentFemalePercentage: 0 as any,

      Totalcertification: 0 as any,
      TotalcertificationFemale: 0 as any,
      TotalcertificationFemalePercentage: 0 as any,

      Totaljobplacement: 0 as any,
      TotaljobplacementFemale: 0 as any,
      TotaljobplacementFemalePercentage: 0 as any,

      TotalCertificationJobPlacementPercentage: 0 as any,
    };
  },
  async created() {
    await this.getAssociation();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      formData.set('entity', this.entity_id);
      formData.set('type', this.report_type);
      let data = `${this.VUE_APP_API_URL}/api/report/course_training_statistic_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();


      formData.set('entity', this.entity_id);
       formData.set('type', this.report_type);

      await ApiService.post('report/course_training_statistic_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();


      formData.set('entity', this.entity_id);
       formData.set('type', this.report_type);


      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/course_training_statistic_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'course_training_statistics.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async courseTrainningStatistics() {
      this.load = true;
      this.showTrainningStatistics = true;
      await ApiService.get(
        'course/trainningStatisticReport?entity=' + this.entity_id + '&type=' +
        this.report_type
      )
        .then((response) => {
          this.activityReport = response.data.data.coursedata;
          this.TotalTrainee = response.data.data.TotalTrainee;

          this.Totalenrollment = response.data.data.Totalenrollment;
          this.TotalenrollmentFemale = response.data.data.TotalenrollmentFemale;
          this.TotalenrollmentFemalePercentage =
            response.data.data.TotalenrollmentFemalePercentage;

          this.Totalassesment = response.data.data.Totalassesment;
          this.TotalassesmentFemale = response.data.data.TotalassesmentFemale;
          this.TotalassesmentFemalePercentage =
            response.data.data.TotalassesmentFemalePercentage;

          this.Totalcertification = response.data.data.Totalcertification;
          this.TotalcertificationFemale =
            response.data.data.TotalcertificationFemale;
          this.TotalcertificationFemalePercentage =
            response.data.data.TotalcertificationFemalePercentage;

          this.Totaljobplacement = response.data.data.Totaljobplacement;
          this.TotaljobplacementFemale =
            response.data.data.TotaljobplacementFemale;
          this.TotaljobplacementFemalePercentage =
            response.data.data.TotaljobplacementFemalePercentage;

          this.TotaljobplacementFemalePercentage =
            response.data.data.TotaljobplacementFemalePercentage;

          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async btnCheckStatus() {
      this.btnCheck = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
